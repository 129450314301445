html {
	font-size: 62.5%;
}

body {
	font-family: 'Bellota', cursive;
	height: 95vh;
	width: auto;
	background-image: linear-gradient(to bottom right, #220535, #31081f);
	display: fixed;
}

.header {
	color: #d782ba;
	background-image: linear-gradient(to bottom left, #a5668b, #d782ba);
	background-size: cover;
	background-position: top;
	clip-path: polygon(0 0, 100% 0, 83% 100%, 17% 100%);
	height: 50vh;
	display: grid;
	align-items: center;
	justify-items: center;
}
.header__title {
	text-transform: uppercase;
	color: #31081f;
	font-size: 0rem;
	font-weight: 100;
	transition: all 0.5s;
	opacity: 0;
}
.header:hover h1 {
	font-size: 10rem;
	opacity: 1;
	transform: translateY(15vh);
}

.family-tree {
	background: radial-gradient(#822e81, #220535);
	clip-path: polygon(0 0, 0 100%, 40% 100%, 50% 10%, 60% 100%, 100% 100%, 100% 0);
	padding: 4rem 0;
	margin: 5rem 2rem;
	display: grid;
	grid-template-columns: repeat(2, minmax(1rem, 1fr));
	grid-gap: 5vw;
	align-items: center;
	justify-items: center;
	border-radius: 5rem;
}
.family-tree div {
	transition: all 0.3s;
	padding: 1rem 2rem;
	border-radius: 10rem;
	cursor: pointer;
}
.family-tree--left {
	justify-self: end;
	align-self: end;
	transform: rotate(-20deg);
}
.family-tree--left:hover {
	transform: rotate(-10deg) scale(1.2) translate(-2rem, -1rem);
	box-shadow: 0.3rem 0.5rem 1rem rgba(215, 130, 186, 0.2);
}
.family-tree--left:active {
	transform: rotate(-15deg) scale(1.1) translate(-2rem, -1rem);
	box-shadow: 0.1rem 0.5rem 1rem rgba(215, 130, 186, 0.1);
}
.family-tree--right {
	justify-self: start;
	align-self: start;
	transform: rotate(20deg);
}
.family-tree--right:hover {
	transform: rotate(10deg) scale(1.2) translate(2rem, -1rem);
	box-shadow: -0.3rem 0.5rem 1rem rgba(215, 130, 186, 0.2);
}
.family-tree--right:active {
	transform: rotate(15deg) scale(1.1) translate(2rem, -1rem);
	box-shadow: -0.1rem 0.5rem 1rem rgba(215, 130, 186, 0.1);
}
.family-tree--mom-dad {
	font-size: 2rem;
	grid-row: 1;
	grid-column: 1/-1;
	grid-gap: 5rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
.family-tree--taylor-becca {
	font-size: 1.5rem;
	grid-row: 2;
	grid-column: 1/-1;
	grid-gap: 8rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
.family-tree--trey-carissa {
	font-size: 1.5rem;
	grid-row: 3;
	grid-column: 1/-1;
	grid-gap: 12rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
.family-tree--dan-carissa {
	font-size: 1.5rem;
	grid-row: 4;
	grid-column: 1/-1;
	grid-gap: 16rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
.family-tree--carly {
	font-size: 1.5rem;
	grid-row: 5;
	grid-column: 1/-1;
	grid-gap: 20rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
.family-tree--tucker-toby {
	font-size: 1.5rem;
	grid-row: 6;
	grid-column: 1/-1;
	grid-gap: 25rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
.family-tree--trevor {
	font-size: 1.5rem;
	grid-row: 7;
	grid-column: 1/-1;
	grid-gap: 30rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
.family-tree--cami {
	font-size: 1.5rem;
	grid-row: 8;
	grid-column: 1/-1;
	grid-gap: 35rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
.family-tree--trent-carolyn {
	font-size: 1.5rem;
	grid-row: 9;
	grid-column: 1/-1;
	grid-gap: 40rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
.family-tree__parent {
	background-color: #822e81;
}
.family-tree__sibling {
	background-color: #d782ba;
}
.family-tree__child {
	background-color: #a5668b;
}
.family-tree__dog {
	color: #fff;
	background-color: #220535;
}

.family-cards-section {
	height: 33rem;
	margin: 5rem 0;
	padding: 10vh 5vw;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 5vw;
	justify-content: center;
	align-content: center;
	justify-items: center;
	align-items: center;
}

.family-card {
	position: relative;
	perspective: 250rem;
	height: 20rem;
	width: 25rem;
}
.family-card:hover .family-card__side--front {
	transform: rotateY(180deg);
	opacity: 0.5;
}
.family-card:hover .family-card__side--back {
	transform: rotateY(0);
	opacity: 1;
}
.family-card__side {
	transition: opacity 0.3s, transform 0.9s;
	top: 0;
	left: 0;
	width: 100%;
	height: 20rem;
	position: absolute;
	backface-visibility: hidden;
	border-radius: 0.85rem;
	box-shadow: 0.3rem 0.3rem 1rem rgba(165, 102, 139, 0.16);
}
.family-card__side--front {
	background-color: #d782ba;
	display: grid;
	align-content: center;
	justify-content: center;
}
.family-card__side--front svg {
	stroke: #822e81;
	fill: #822e81;
}
.family-card__side--back {
	background-image: linear-gradient(to bottom left, #a5668b, #d782ba);
	transform: rotateY(-180deg);
	opacity: 0.5;
	display: grid;
	align-content: center;
	align-items: center;
	justify-content: center;
	justify-items: center;
	grid-template-columns: 1fr;
}

.family-card__phones {
	background-color: #48174c;
	color: white;
}

.family-card__photos {
	background-color: #48174c;
	color: white;
}

.family-card__calendar {
	background-color: #48174c;
	color: white;
}

.btn {
	border-radius: 5rem;
	margin: 1rem;
	background-color: #220535;
	height: 4rem;
	width: 20rem;
	text-decoration: none;
	align-self: center;
	justify-self: center;
	display: grid;
	align-content: center;
	justify-content: center;
	transition: 0.2s;
}
.btn:hover {
	transform: scale(1.05);
}
.btn span {
	font-style: italic;
	font-size: 1.5rem;
	color: #d782ba;
	font-weight: 200;
}

a:hover,
a:visited,
a:link,
a:active {
	text-decoration: none;
	color: inherit;
}

.color-pallet {
	padding: 2rem;
	margin: 2rem;
	height: 30vh;
	width: 95vw;
	background-image: linear-gradient(to top right, #eed1ff, #200d2b);
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	justify-items: center;
	align-content: center;
	justify-content: center;
	align-items: center;
	border-radius: 3rem;
}

.square {
	width: 10rem;
	height: 10rem;
	transition: all 5s;
	border-radius: 3rem;
	display: grid;
	align-content: center;
	justify-content: center;
}
.square span {
	font-size: 1.1rem;
}
.square:hover {
	transform: rotate(720deg) scale(1.3);
}

.purps {
	background-color: #48174c;
	color: #eed1ff;
}

.purps-dark {
	background-color: #31081f;
	color: #eed1ff;
}

.purps-super-dark {
	background-color: #220535;
	color: #eed1ff;
}

.purps-darkest {
	background-color: #200d2b;
	color: #eed1ff;
}

.purps-light {
	background-color: #a5668b;
	color: #200d2b;
}

.purps-super-light {
	background-color: #d782ba;
	color: #200d2b;
}

.purps-lightest {
	background-color: #eed1ff;
	color: #200d2b;
}

.purps-max {
	background-color: #822e81;
	color: #200d2b;
}
